<template>
  <div id="mobile" @click="hideQr">
    <div class="container">
      <div class="page-bg"></div>
      <div class="header" :class="{scroll:isScroll}">
        <div class="lPart">
          <img src="./logo.png" alt="" class="logo">
          <img src="./wifi.png" alt="" class="wifi">
        </div>
        <div class="rPart" @click.stop="showQrjump">
          <img src="./qr.png" alt="">
        </div>
      </div>
      <div class="qrJump" v-show="qrJump" @click="showQrjump">
        <img src="./qr-gzh.png" alt="">
      </div>
      <div class="topBanner">
        <!-- <div class="tool"></div>
        <div class="ad">
          <img src="./new.png" alt="">
          <p>无线掌上智能彩超</p>
        </div> -->
        <div class="topBannerBox" ref="topBannerBox">
          <img src="./tools.png" alt="" class="toolImg">
          <!-- <div class="adImg">
            <img src="./new.png" alt="">
            <p>无线掌上智能彩超</p>
          </div> -->
        </div>
      </div>
      <div class="infoPart wow fadeInUp" data-wow-duration=".8s">
        <section class="blc blc1 wow fadeInUp" data-wow-duration=".8s">
          <div class="content">
            <div class="title wow fadeInUp" data-wow-delay=".5s">
              <h3>用不起的用得起</h3>
              <h3>不会用的轻松用</h3>
            </div>
            <h4 class="wow fadeInUp" data-wow-delay=".8s">0元入驻<span></span>20秒上手<span></span>检查精准<span></span>报告权威</h4>
            <ul>
              <li class="wow fadeInUp" data-wow-delay=".6s">
                <img src="./blc1-1.png" alt="">
                <h5 >未来医疗</h5>
                <h6>100强</h6>
              </li>
              <li class="wow fadeInUp" data-wow-delay=".7s">
                <img src="./blc1-2.png" alt="">
                <h5>济南创业奖</h5>
                <h6>TOP10</h6>
              </li>
              <li class="wow fadeInUp" data-wow-delay=".8s">
                <img src="./blc1-3.png" alt="">
                <h5>杭州创业大赛</h5>
                <h6>入围奖</h6>
              </li>
            </ul>
          </div>
        </section>
        <!-- 身边即享超声服务 -->
        <section class="blc blc2 wow fadeInUp" data-wow-duration=".8s">
          <div class="content">
            <div class="p-title wow fadeInUp" data-wow-delay=".5s">
              <h3>身边即享超声服务</h3>
              <h4>Soundwise technology</h4>
            </div>
            <!-- <img src="./fake.gif" alt="" class="wow fadeInUp" data-wow-delay=".6s"> -->
            <div id="lottieWrap">
              <div id="lottie"></div>
            </div>
            <div class="tip wow fadeInUp" data-wow-delay=".7s">
              <h4>2分钟检查过程</h4>
              <h4>10分钟获得报告</h4>
            </div>
            <button class="wow fadeInUp" data-wow-delay=".8s" @click="qrPopupShow">立即联系我们</button>
            <p class="wow fadeInUp" data-wow-delay=".9s">简单易用的操作方式，引导非超声医师2分钟完成检查；远端专业医师，快速响应，10分钟出具专业报告</p>
          </div>
        </section>
        <!-- 我们的使命 -->
        <section class="blc blc3 wow fadeInUp" data-wow-duration=".8s">
          <div class="content">
            <div class="p-title wow fadeInUp" data-wow-delay=".5s">
              <h3>我们的使命</h3>
              <h4>打造基层影像家园</h4>
            </div>
            <img src="./doctor.png" alt="" class="wow fadeInUp" data-wow-delay=".6s">
            <p class="wow fadeInUp" data-wow-delay=".7s">无场地要求、无影像科要求、赋能基层诊所，共创基层影像家园</p>
            <a @click.stop="qrPopupShow" class="wow fadeInUp" data-wow-delay=".7s">进一步了解我们</a>
          </div>
        </section>
        <!-- 我们的团队 -->
        <section class="blc blc4 wow fadeInUp" data-wow-duration=".8s">
          <div class="content">
            <div class="p-title wow fadeInUp" data-wow-delay=".5s">
              <h3>我们的团队</h3>
              <h4>Soundwise technology</h4>
            </div>
            <p class="wow fadeInUp" data-wow-delay=".6s">深至科技专注于超声诊断智能化和超声AI应用，团队由哥伦比亚、麻省理工、哈佛、斯坦福、清华、上海交大等国内外知名高校的博士率领。在小型超声设备硬件、人工智能系统方面拥有国内外的领先技术。</p>
            <!-- <van-swipe class="boss-swipe wow fadeInUp" :autoplay='false' :duration="900" indicator-color="#2779FF" data-wow-delay=".7s" @change="onChangeSwipe1">
              <van-swipe-item>
                <img src="./boss1.png" alt="">
                <h3>
                  朱瑞星<span>CEO</span>
                </h3>
                <p>哥伦比亚大学博士</p>
                <p>拥有近十年医疗领域研发和投资经验</p>
                <p>曾任于软银中国医疗投资团队投资总监</p>
              </van-swipe-item>
              <van-swipe-item>
                <img src="./boss2.png" alt="">
                <h3>
                  张卓<span>董事长</span>
                </h3>
                <p>拥有11年超声行业经验</p>
                <p>参与多个掌上超声项目研发和推广</p>
                <p>曾任上海交通大学附属瑞金医院超声科医生</p>
              </van-swipe-item>
              <template #indicator>
                <ul class="spots">
                  <li :class="[swipe1Current== 0? 'highlight': '']"></li>
                  <li :class="[swipe1Current== 1? 'highlight': '']"></li>
                </ul>
              </template>
            </van-swipe> -->
          </div>
        </section>
        <!-- 软件功能介绍 -->
        <section class="blc blc5 wow fadeInUp" data-wow-duration=".8s">
          <div class="content">
            <div class="p-title wow fadeInUp" data-wow-delay=".5s">
              <h3>软件功能介绍</h3>
              <h4>Soundwise technology</h4>
            </div>
            <van-swipe class="software-swipe wow fadeInUp" :autoplay='false' :duration='800' indicator-color="#2779FF" data-wow-delay=".6s" @change="onChangeSwipe2">
              <van-swipe-item>
                <img src="./software1.png" alt="">
                <div class="info">
                  <h3>
                    适用范围广泛
                  </h3>
                  <p>腹部、血管、浅表、泌尿、妇科</p>
                </div>
              </van-swipe-item>
              <van-swipe-item>
                <img src="./software2.png" alt="">
                <div class="info">
                  <h3>
                    智能导航
                  </h3>
                  <p>部位识别、方位识别、病理识别</p>
                </div>
              </van-swipe-item>
              <van-swipe-item>
                <img src="./software3.png" alt="">
                <div class="info">
                  <h3>
                    多种图像模式
                  </h3>
                  <p>灰阶模式、彩阶模式、频谱模式</p>
                </div>
              </van-swipe-item>
              <template #indicator>
                <ul class="spots">
                  <li :class="[swipe2Current== 0? 'highlight': '']"></li>
                  <li :class="[swipe2Current== 1? 'highlight': '']"></li>
                  <li :class="[swipe2Current== 2? 'highlight': '']"></li>
                </ul>
              </template>
            </van-swipe>
          </div>
        </section>
        <!-- 权威认证 -->
        <section class="blc blc6 wow fadeInUp" data-wow-duration=".8s">
          <div class="content">
            <div class="p-title wow fadeInUp" data-wow-delay=".5s">
              <h3>权威认证</h3>
              <h4>Soundwise technology</h4>
            </div>
            <ul>
              <li class="wow fadeInUp" data-wow-delay=".6s">
                <img src="./authority1.png" alt="">
                <h3>2021年世界人工智能大会</h3>
              </li>
              <li class="wow fadeInUp" data-wow-delay=".7s">
                <img src="./authority2.png" alt="">
                <h3>2021年动脉网未来医疗100强</h3>
              </li>
              <!-- <li>
                <video class="video" autoplay="autoplay" controls="controls" poster="./authority2.png">
                  <source src="https://www.w3school.com.cn/i/movie.mp4" type="video/mp4" />
                </video>
              </li> -->
            </ul>
          </div>
        </section>
        <!-- 合作机构 -->
        <section class="blc blc7 wow fadeInUp" data-wow-duration=".8s">
          <div class="content">
            <div class="p-title wow fadeInUp" data-wow-delay=".5s">
              <h3>合作机构</h3>
              <h4>Soundwise technology</h4>
            </div>
            <ul>
              <li class="wow fadeInUp" data-wow-delay="0.7s">
                <img src="./shjy.png" alt="">
                <h4>上海九院</h4>
              </li>
              <li class="wow fadeInUp" data-wow-delay="0.8s">
                <img src="./zjszlyy.png" alt="">
                <h4>浙江省肿瘤医院</h4>
              </li>
              <li class="wow fadeInUp" data-wow-delay="0.9s">
                <img src="./rjyy.png" alt="">
                <h4>瑞金医院</h4>
              </li>
              <li class="wow fadeInUp" data-wow-delay="1.0s">
                <img src="./zdyy.png" alt="">
                <h4>浙大一院</h4>
              </li>
              <li class="wow fadeInUp" data-wow-delay="1.1s">
                <img src="./mszyy.png" alt="">
                <h4>麻省总医院</h4>
              </li>
              <li class="wow fadeInUp" data-wow-delay="1.2s">
                <img src="./shczyy.png" alt="">
                <h4>上海长征医院</h4>
              </li>
              <li class="wow fadeInUp" data-wow-delay="1.3s">
                <img src="./bjdx.png" alt="">
                <h4>北京大学</h4>
              </li>
              <li class="wow fadeInUp" data-wow-delay="1.4s">
                <img src="./zky.png" alt="">
                <h4>中科院</h4>
              </li>
              <li class="wow fadeInUp" data-wow-delay="1.5s">
                <img src="./shjd.png" alt="">
                <h4>上海交大</h4>
              </li>
              <li class="wow fadeInUp" data-wow-delay="1.6s">
                <img src="./njykdx.png" alt="">
                <h4>南京医科大学</h4>
              </li>
              <li class="wow fadeInUp" data-wow-delay="1.7s">
                <img src="./glbydx.png" alt="">
                <h4>哥伦比亚大学</h4>
              </li>
              <li class="wow fadeInUp" data-wow-delay="1.8s">
                <img src="./mslg.png" alt="">
                <h4>麻省理工</h4>
              </li>
            </ul>
          </div>
        </section>
        <!-- 我们的掌上超声设备 -->
        <!-- <section class="blc blc8 wow fadeInUp" data-wow-duration=".8s">
          <div class="content">
            <div class="p-title wow fadeInUp" data-wow-duration=".5s">
              <h3>服务赋能</h3>
              <h4>Soundwise technology</h4>
            </div>
            <img src="./map.png" alt="" class="wow fadeInUp" data-wow-duration=".6s">
            <p class="wow fadeInUp" data-wow-duration=".7s">我们已服务全国多省市诊所，已帮助<br/>千万位患者守护健康</p>
            <button class="wow fadeInUp" data-wow-duration=".8s" @click="qrPopupShow">立即联系我们</button>
          </div>
        </section> -->
        <!-- 我们期待您的加入 -->
        <section class="blc blc9 wow fadeInUp" data-wow-duration=".8s">
          <div class="content">
            <div class="p-title wow fadeInUp" data-wow-duration=".5s">
              <h3>我们期待您的加入！</h3>
              <h4>Soundwise technology</h4>
            </div>
            <div class="board">
              <!-- <div class="info wow fadeInUp" data-wow-duration=".6s">
                <p>扫描下面二维码访问公众号和App</p>
              </div> -->
              <img src="./tool.png" alt="" class="wow fadeInUp" data-wow-duration=".7s">
              <ul class="wow fadeInUp" data-wow-duration=".8s">
                <li>
                  <img src="./qr-gzh.png" alt="">
                  <h3>公众号</h3>
                </li>
                <li>
                  <img src="./qr-weicha.png" alt="">
                  <h3>微查App</h3>
                </li>
              </ul>
            </div>
            <div class="copyright">
              <!-- <p>沪ICP备19048066号</p>    -->
              <a href="https://beian.miit.gov.cn/">沪ICP备19048066号-4</a>
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502017406/">沪公网安备 31011502017406号</a>
              <p>Copyright © 2020-2021 版权所有</p>
            </div>
            <!-- <div class="line"></div> -->
          </div>
        </section>
      </div>
    </div>
    <div class="loading" v-show="loadingShow"></div>
    <van-popup v-model="showPopup" class="popupQr">
      <img src="./qr-gzh.png" alt="" class="qr">
      <img src="./close.png" alt="" class="close" @click='qrPopupClose'>
    </van-popup>
  </div>
</template>
<script>
import { WOW } from 'wowjs'
import animationData from '../../../public/data.json'
export default {
  data() {
    return {
      isScroll: false,
      qrJump: false,
      showPopup: false,
      loadingShow: true,
      // 第一个swipe的索引
      swipe1Current: 0,
      // 第二个swipe的索引
      swipe2Current: 0,
    }
  },
  created() {
    window.addEventListener('scroll', this.scrollHandle);
  },
  mounted() {
    // window.addEventListener('click', this.hideQr);
    this.$nextTick(() => {
      let wow = new WOW({
        offset: 0,
        live: false
      })
      this.loadingShow = false
      wow.init()
    })
    console.log(document)
    /* eslint-disable */
    var params = {
        container: document.getElementById('lottie'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData
    };

    var anim;

    anim = lottie.loadAnimation(params);
    /* eslint-enable */
  },
  methods: {
    scrollHandle() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const rollNum = 400
      let rollMath = 1-(scrollTop/rollNum)
      this.$refs.topBannerBox.style.opacity = rollMath
      // this.$refs.adImg.style.opacity = rollMath
      if (scrollTop > 10 ) {
        this.isScroll = true
      } else {
        this.isScroll = false
      }
    },
    hideQr() {
      if(this.qrJump) {
        this.qrJump = false
      }
    },
    showQrjump () {
      this.qrJump = !this.qrJump
    },
    qrPopupShow() {
      this.showPopup = true
    },
    qrPopupClose() {
      this.showPopup = false
    },
    // 第一个swipe
    onChangeSwipe1(index) {
      this.swipe1Current = index;
    },
    // 第二个swipe
    onChangeSwipe2(index) {
      this.swipe2Current = index;
    },
  }
}
</script>
<style lang="scss" scoped>
  .container {
    background-color: #010D1A;
    font-family: PingFangSC-Medium, PingFang SC;
    .page-bg {
      position: fixed;
      z-index: -1;
      background: #010D1A;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .header {
      height: 94px;
      line-height: 94px;
      background: transparent;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 999;
      display: flex;
      justify-content: space-between;
      transition: all .3s;
      &.scroll {
        background-color: #010D1A;
      }
      .lPart {
        display: flex;
        align-items: center;
        margin-left: 32px;
        .logo {
          width: 132px;
          height: 20px;
          margin-right: 10px;
        }
        .wifi {
          width: 25px;
          height: 25px;
          margin-top: 1px;
        }
      }
      .rPart {
        margin-right: 24px;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .qrJump {
      width: 100px;
      height: 100px;
      background-color: green;
      position: fixed;
      top: 60px;
      right: 24px;
      z-index: 999;
      img {
        width: 100px;
        height: 100px;
      }
    }
    .topBanner {
      width: 414px;
      // height: 786px;
      // background: url('./topBg.png') no-repeat top center ;
      background-color: #010D1A;
      background-size: 100%;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-size: cover;
      .topBannerBox {
        background: url('./topBg.png') no-repeat top center ;
        background-size: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 11;
          .toolImg {
          width: 316px;
          height: 555px;
          position: absolute;
          top: 143px;
          right: 0;
        }
        .tool {
          width: 316px;
          height: 555px;
          // background: url('./tool.png') no-repeat right center;
          // background-size: 316px 555px;
          position: absolute;
          top: 143px;
          right: 0;
          // background-attachment: fixed;
          &:before {
            content: ' ';
            position: fixed;
            z-index: 0;
            top: 143px;
            right: 0;
            bottom: 0;
            left: 98px;
            background: url('./tools.png') center 0 no-repeat;
            // background-size: cover;
            background-size: 316px 555px;
          }
        }
        .adImg {
          color: #fff;
          position: fixed;
          top: 462px;
          left: 32px;
          z-index: 0;
          img {
            width: 32px;
            height: 14px;
          }
          p {
            font-size: 12.5px;
            line-height: 20px;
          }
        }
        .ad {
          color: #fff;
          position: fixed;
          top: 462px;
          left: 32px;
          z-index: 0;
          img {
            width: 32px;
            height: 14px;
          }
          p {
            font-size: 12.5px;
            line-height: 20px;
          }
        }
      }
    }
    .infoPart {
      // display: none;
      position: relative;
      z-index: 9;
      // background-color: #010D1A;
      width: 394px;
      margin: 577px auto 0;
      padding-bottom: 35px;
      .blc {
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        // padding: 30px 27px 27px 30px;
        margin: 0px auto 10px;
        width: 394px;
        background-color: #19202C;
        border-radius: 30px;
        overflow: hidden;
        .p-title {
          padding: 30px;
          h3 {
            color: #fff;
            font-size: 24px;
            line-height: 33px;
          }
          h4 {
            color: #666;
            font-size: 14px;
            line-height: 20px;
          }
        }
        &.blc1 {
          margin: 0px auto 10px;
          width: 394px;
          height: 279px;
          background-color: #19202C;
          border-radius: 30px;
          .title {
            font-size: 26px;
            color: #fff;
            line-height: 40px;
            padding: 30px 30px 10px;
          }
          h4 {
            font-size: 16px;
            margin-top: 10px;
            color: #fff;
            padding: 0 30px;
            span {
              display: inline-block;
              width: 12px;
              height: 10px;
              // background: red;
            }
          }
          ul {
            margin-top: 25px;
            display: flex;
            justify-content: space-between;
            padding: 0 30px;
            li {
              text-align: center;
              img {
                display: inline-block;
                width: 36px;
                height: 36px;
              }
              h5 {
                color: #999;
                font-size: 14px;
                margin-top: 6px;
                line-height: 20px;
              }
              h6 {
                color: #fff;
                font-size: 16px;
                font-weight: bold;
                line-height: 19px;
              }
            }
          }
        }
        &.blc2 {
          padding-bottom: 17px;
          margin: 0px auto 10px;
          width: 394px;
          background-color: #19202C;
          border-radius: 30px;
          .p-title {
            padding: 30px 30px 0;
            margin-bottom: 25px;
          }
          #lottieWrap {
            width: 334px;
            height: 260px;
            margin: 0 auto 25px;
            #lottie{
              background-color:#000000;
              width:100%;
              height:100%;
              display:block;
              overflow: hidden;
              transform: translate3d(0,0,0);
              text-align: center;
              opacity: 1;
            }
          }
          img {
            display: block;
            width: 334px;
            height: 260px;
            margin: 0 auto 25px;
            // vertical-align: top;
          }
          .tip {
            font-size: 18px;
            line-height: 25px;
            color: #fff;
            margin-bottom: 19px;
            padding-left: 30px;
          }
          button {
            width: 120px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            color: #fff;
            background: #2779FF;
            border: 0 none;
            border-radius: 2px;
            margin-left: 30px;
          }
          p {
            width: 334px;
            font-size: 14px;
            color: #fff;
            line-height: 22px;
            margin-top: 20px;
            margin-left: 30px;
          }
        }
        &.blc3 {
          padding-bottom: 28px;
          margin: 0px auto 10px;
          width: 394px;
          background-color: #19202C;
          border-radius: 30px;
          .p-title {
            padding: 30px;
            h3 {
              font-size: 16px;
              color: #FFFFFF;
              line-height: 22px;
              margin-bottom: 8px;
            }
            h4 {
              font-size: 24px;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 33px;
            }
          }
          img {
            width: 334px;
            margin: 0 auto;
            display: block;
          }
          p {
            width: 310px;
            font-size: 14px;
            line-height: 22px;
            color: #fff;
            margin-top: 23px;
            margin-bottom: 10px;
            padding-left: 30px;
          }
          a {
            display: inline-block;
            color: #2779FF;
            font-size: 14px;
            height: 20px;
            border-bottom: 1px solid #2779FF;
            margin-left: 30px;
          }
        }
        &.blc4 {
          padding-bottom: 19px;
          .p-title {
            padding-bottom: 0;
            margin-bottom: 20px;
          }
          .spots {
            text-align: center;
            height: 6px;
            transition: all .6s;
            li {
              display: inline-block;
              vertical-align: top;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: #D8D8D8;
              margin: 0 3px;
              &.highlight {
                width: 18px;
                height: 6px;
                border-radius: 5px;
                background-color: #2779FF;
              }
            }
          }
          p {
            font-size: 16px;
            line-height: 25px;
            color: #fff;
            // width: 337px;
            padding: 0 30px;
            margin: 0 auto;
          }
           .boss-swipe {
            .van-swipe-item {
              text-align: center;
              padding-top: 29px;
              padding-bottom: 13px;
              img {
                width: 80px;
                height: 80px;
              }
              h3 {
                font-size: 17px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 24px;
                text-align: center;
                margin-bottom: 12px;
                span {
                  font-size: 14px;
                  font-weight: 400;
                  color: #C6C6C6;
                  line-height: 20px;
                  font-weight: 400;
                  margin-left: 5px;
                }
              }
              p {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
              }
            }
           }
        }
        &.blc5 {
          padding-bottom: 30px;
          .p-title {
            padding-bottom: 0;
          }
          .spots {
            text-align: center;
            height: 6px;
            transition: all .6s;
            margin-top: 13px;
            li {
              display: inline-block;
              vertical-align: top;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: #D8D8D8;
              margin: 0 3px;
              &.highlight {
                width: 18px;
                height: 6px;
                border-radius: 5px;
                background-color: #2779FF;
              }
            }
          }
          .software-swipe {
            text-align: center;
            // padding-bottom: 130px;
            img {
              width: 289px;
              height: 491px;
            }
            .info {
              margin-top: -25px;
              h3 {
                color: #fff;
                font-size: 18.09px;
                line-height: 25px;
                font-weight: 500;
                margin-bottom: 6px;
              }
              p {
                font-size: 14.89px;
                line-height: 21px;
                color: #fff;
                font-weight: 400x;
              }
            }
          }
        }
        &.blc6 {
          // padding: 30px 20px 30px;
          .video {
            width: 354px;
            height: 140px;
          }
          ul {
            margin: 0 auto;
            // width: 354px;
            padding: 0 30px;
            li {
              img {
                width: 334px;
                height: 140px;
              }
              h3 {
                margin-top: 15px;
                margin-bottom: 30px;
                color: #BCBCBC;
                font-size: 16px;
                line-height: 22px;
              }
            }
          }
        }
        &.blc7 {
          // padding: 0px 24px 30px;
          padding-bottom: 31px;
          ul {
            // padding: 0 24px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
              width: 33%;
              text-align: center;
              margin-bottom: 26px;
              &:nth-last-child(1),
              &:nth-last-child(2),
              &:nth-last-child(3) {
                margin-bottom: 0;
              }
              img {
                width: 50px;
                height: 50px;
                margin-bottom: 8px;
              }
              h4 {
                color: #BCBCBC;
                font-size: 15px;
                line-height: 21px;
              }
            }
          }
        }
        &.blc8 {
          .p-title {
            padding: 30px 27px 0;
            margin-bottom: 25px;
          }
          img {
            width: 315px;
            height: 260px;
            display: block;
            margin: 0 auto 25px;
          }
          p {
            width: 339px;
            margin: 0 auto;
            color: #fff;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 20px;
          }
          button {
            width: 120px;
            height: 32px;
            line-height: 20px;
            font-size: 14px;
            color: #fff;
            background: #2779FF;
            border: 0 none;
            border-radius: 2px;
            margin-left: 27px;
            margin-bottom: 35px;
          }
        }
        &.blc9 {
          font-size: 16px;
          line-height: 24px;
          color: #fff;
          padding-bottom: 17px;
          .p-title {
            padding-bottom: 0;
          }
          .board {
            width: 340px;
            height: 482px;
            // background-color: green;
            margin: 0 auto;
            position: relative;
            .info {
              width: 144px;
            }
            &>img {
              width: 249px;
              height: 442px;
              // background-color: red;
              position: absolute;
              left: 74px;
              top: 26px;
            }
            ul {
              width: 100%;
              // height: 164px;
              background-color: #19202C;
              // display: flex;
              // justify-content: center;
              position: absolute;
              padding: 33px 0 0;
              top: 319px;
              li {
                display: inline-block;
                width: 100px;
                &:first-child {
                  margin-right: 61px;
                }
                img {
                  width: 100px;
                  height: 100px;
                }
                h3 {
                  text-align: center;
                }
              }
            }
          }
          .copyright {
            width: 340px;
            margin: 23px auto 0;
            font-size: 15.46px;
            line-height: 21px;
            color: #666;
            a {
              color: #666;
              display: block;
            }
          }
          .line {
            width: 354px;
            height: 8.83px;
            background-color: #2779FF;
            margin: 29px auto 0;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .loading {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000;
    z-index: 999;
  }
  .popupQr {
    background: transparent;
    top: 40%;
    .qr {
      width: 180px;
      height: 180px;
      display: block;
      margin: 0 auto 30px;
    }
    .close {
      width: 36px;
      height: 36px;
      display: block;
      margin: 0 auto;
    }
  }
</style>
